<template>
  <div v-loading="loading" element-loading-text="订单生成中…" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" class="con-box">
    <div class="top-box" v-if="looktype == 1" :style="{
        backgroundImage: `url(${detail.file && detail.file.image_url})` }">
      <div class="top-box-in">
        <p class="title">{{ detail.name }}</p>
        <p class="num">编码：{{ detail.serial_number }}</p>
        <p class="info">{{ detail.subject }}</p>
        <div class="price">
          <span class="price-now">￥{{ currentProduct.sale_price }}</span>
          <span class="price-delt">￥{{ currentProduct.base_price }}</span>
        </div>
        <div class="validity-list">
          <div v-for="item in detail.product_sku" :class="{ 'validity-active': item.checked }" :key="item.id" @click="selectGoods(item)">
            {{ item.validity }}天
          </div>
        </div>
        <div class="submit-order-btn" @click="submitOrder(0)">立即下单</div>
        <p class="current-login">当前登录企业：{{company.name || '--'}}</p>
      </div>
    </div>
    <div class="main-box" v-if="looktype ==1">
      <div class="contents" style="padding: 20px 0;margin-top: 8px;">
        <div class="content-box">
          <div class="content-box-top flexBt flexCt">
            <div style="width: 103px;"></div>
            <p>同类商品推荐</p>
            <div class="mut-btn" @click="submitOrder(1)" v-if="recoomList && recoomList.length !==0">批量下单</div>
            <div v-else style="width: 103px;"></div>
          </div>
        </div>
        <div class="bor-box">
          <el-table :header-cell-style="styleObj" :data="recoomList" style="width: 100%;">
            <el-table-column width="150">
              <template slot-scope="{ row }">
                <div :class="['select-item', { 'select-item-active': row.checked }]" @click="selectReGoods(row)">
                  <el-image style="width: 100%; height: 100%; border-radius: 5px" :src="row.product && row.product.master_file && row.product.master_file.image_url">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <div class="chekbox-box" v-show="row.checked">
                    <i class="el-icon-check"></i>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="100">
              <template slot="header">
                <p>商品信息</p>
              </template>
              <template slot-scope="{ row }">
                <div class="p-title">{{ row.product && row.product.name }}</div>
                <div class="othenr">
                  商品编码：{{ row.product && row.product.serial_number }}
                </div>
                <div class="othenr">商品sku：{{ row.sku_number }}</div>
              </template>
            </el-table-column>
            <el-table-column min-width="100">
              <template slot="header">
                <p>商品副标题</p>
              </template>
              <template slot-scope="{ row }">
                <p class="ellipsisText3" :title="(row.product && row.product.subject) || '--'">
                  {{ row.product && row.product.subject }}
                </p>
              </template>
            </el-table-column>
            <el-table-column min-width="100">
              <template slot="header">
                <p>有效期（天）</p>
              </template>
              <template slot-scope="{ row }">
                <p>{{ row.validity }}天</p>
              </template>
            </el-table-column>

            <el-table-column min-width="100">
              <template slot="header">
                <p>销售价</p>
                <p>划线价</p>
              </template>
              <template slot-scope="{ row }">
                <p class="sale-price">￥{{ row.sale_price }}</p>
                <p class="base-price">￥{{ row.base_price || "--" }}</p>
              </template>
            </el-table-column>
            <el-table-column min-width="140">
              <template slot="header">
                <p>应用计划开始时间</p>
              </template>
              <template slot-scope="{ row }">
                <el-date-picker v-model="row.app_start_at" type="datetime" placeholder="选择日期时间">
                </el-date-picker>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="mobile-mian" v-if="looktype == 2">
      <div class="mobile-bg" :style="{
        backgroundImage: `url(${detail.file && detail.file.image_url})` }">
        <div class="mb-in">
          <p class="mobile-title">
            {{ detail.name }}
          </p>
          <p class="mobile-info">{{ detail.subject }}</p>
          <div class="validity-list">
            <div v-for="item in detail.product_sku" :class="{ 'validity-active': item.checked }" :key="item.id" @click="selectGoods(item)">
              {{ item.validity }}天
            </div>
          </div>
        </div>
      </div>
      <div class="price-box">
        <p>￥{{ currentProduct.sale_price }}</p>
        <span>{{detail.serial_number}}</span>
      </div>
      <p class="category-box">
        {{detail.category_name || '--'}}
      </p>
      <div class="pub-title">
        同类商品推荐
      </div>
      <div class="goods-list">
        <div :class="['bb' ,{'no-b': recoomList.length % 2 == 0}]" v-if="recoomList.length !== 0">
          <div v-for="item in recoomList" :key="item.id">
            <div class="i-title">
              <!-- {{recoomList.length % 2 == 0}} -->
              <img :src="item.product && item.product.master_file && item.product.master_file.image_url">
              <p class="ellipsisText">{{item.product && item.product.name}}</p>

            </div>
            <div style="height: 60px;">
              <p class="ino ellipsisText2"> {{ item.product && item.product.subject }}</p>
            </div>
            <p class="ppp">{{item.sale_price}}</p>
          </div>
        </div>
        <el-empty v-if="recoomList.length === 0" description="暂无数据" style="border: none;margin: 0 auto;backgorund: #fafafa!important;"></el-empty>
      </div>
    </div>
    <div v-if="looktype == 2" style="padding-top: 21px;padding-bottom: 26px;">
      <div class="pub-title">
        商品详情
      </div>
      <div v-for="item in detail.product_template_up" :key="item.id" style="width: 100%;">
        <apaas v-for="citem in item.apaas" :key="citem.id" :page="citem.page_id || citem.id" />
      </div>
      <apaas v-for="item in detail.product_middle" :key="item.id" :page="item.page_id || item.id" />
      <div v-for="item in detail.product_template_down" :key="item.id" style="width: 100%;">
        <apaas v-for="citem in item.apaas" :key="citem.id" :page="citem.page_id" />
      </div>
    </div>
    <div class="main-box" v-if="looktype == 1">
      <div class="contents" style="padding: 20px 0">
        <div class="detail-title">商品详情</div>
      </div>
      <div v-for="item in detail.product_template_up" :key="item.id" style="width: 100%;">
        <apaas v-for="citem in item.apaas" :key="citem.id" :page="citem.page_id || citem.id" />
      </div>
      <apaas v-for="item in detail.product_middle" :key="item.id" :page="item.page_id || item.id" />
      <div v-for="item in detail.product_template_down" :key="item.id" style="width: 100%;">
        <apaas v-for="citem in item.apaas" :key="citem.id" :page="citem.page_id" />
      </div>
    </div>

  </div>
</template>

<script>
import {
  get_product_detail,
  get_alike_product,
  view_order,
  login_info,
} from "@/assets/api/detailsApi.js";
import apaas from "./../index/components/apaas.vue";
export default {
  components: { apaas },
  data() {
    return {
      sku_id: 67,
      styleObj: {
        color: "#333",
        backgroundColor: "#f5f5f5",
      },
      recoomList: [], //推荐商品列表
      detail: {},
      currentProduct: {
        sale_price: "0.00", //销售价
        base_price: "", //划线价
        product_sku_id: "",
      },
      url: "",
      loading: false,
      company: {},
    };
  },
  mounted() {
    this.url =
      process.env.NODE_ENV == "development" ? "https://aa.testfw.cn" : "";
    this.getDetail();
    this.getRecomProduct();
    if (sessionStorage.getItem("AccessToken")) {
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      login_info().then((res) => {
        if (res.code === 200) {
          if (res.data && res.data.company) {
            this.company = res.data.company;
          }
        }
      });
    },
    selectReGoods(row) {
      row.checked = !row.checked;
    },
    //选择商品有效期
    selectGoods(val) {
      if (this.detail && this.detail.product_sku.length !== 0) {
        this.detail.product_sku.forEach((item) => {
          if (item.id == val.id) {
            item.checked = true;
            this.currentProduct.sale_price = item.sale_price || "--";
            this.currentProduct.base_price = item.base_price || "--";
            this.currentProduct.product_sku_id = item.product_sku_id;
          } else {
            item.checked = false;
          }
        });
        console.log(val);
      }
    },
    //获取商品项
    getDetail() {
      get_product_detail(this.$route.query.id).then((res) => {
        if (res.code === 200) {
          if (res.data && res.data.product_sku.length !== 0) {
            res.data.product_sku.forEach((item, index) => {
              if (index === 0) {
                item.checked = true;
                console.log(item, "item");
                this.currentProduct.sale_price = item.sale_price || "--";
                this.currentProduct.base_price = item.base_price || "--";
                this.currentProduct.product_sku_id = item.product_sku_id;
              } else {
                item.checked = false;
              }
            });
          }
          this.detail = res.data;
        }
      });
    },
    //获取推荐同类商品
    getRecomProduct() {
      get_alike_product({
        product_id: this.$route.query.id,
        category_id: this.$route.query.category_id,
      }).then((res) => {
        if (res.code === 200) {
          this.recoomList = res.data.data.map((item) => {
            item.checked = false;
            item.app_start_at = new Date();
            return item;
          });
        }
      });
    },
    submitOrder(type) {
      //0: 单个下单；1：批量下单
      let product_sku_id = null;
      if (type === 0) {
        product_sku_id = [this.currentProduct.product_sku_id];
      } else {
        if (this.recoomList && this.recoomList.length !== 0) {
          let arr = this.recoomList.filter((item) => item.checked);
          if (arr.length === 0) {
            return this.$message.error("请选择商品！");
          } else {
            product_sku_id = arr.map((item) => item.product_sku_id);
          }
        }
        console.log("==", product_sku_id);
      }
      this.reqSubmit(product_sku_id);
      console.log("=======");
      return;
    },
    reqSubmit(product_sku_id) {
      this.loading = true;
      view_order({
        product_sku_id,
      })
        .then((res) => {
          if (res.code === 200) {
            let data = JSON.stringify(res.data);
            setTimeout(() => {
              this.loading = false;
              localStorage.setItem("orderData", data);
              this.$router.push({
                path: "/shopStore/submitOrder",
              });
            }, 1000);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  width: 1200px;
  margin: 0 auto;
}
.top-box {
  width: 100%;
  height: 413px;
  background: rgba(pink, 0.1);
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  .top-box-in {
    width: 1200px;
    margin: 0 auto;
    .title {
      color: rgba(16, 16, 16, 1);
      font-size: 28px;
      text-align: left;
      font-family: SourceHanSansSC-regular;
    }
    .num {
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
      text-align: left;
      font-family: SourceHanSansSC-regular;
      padding: 12px 0;
    }
    .info {
      width: 770px;
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      text-align: left;
      font-family: SourceHanSansSC-regular;
    }
    .price {
      padding: 26px 0;
      display: flex;
      align-items: center;
      .price-now {
        color: rgba(247, 72, 72, 1);
        font-size: 28px;
        font-family: SourceHanSansSC-bold;
        padding-right: 16px;
      }
      .price-delt {
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        font-family: SourceHanSansSC-regular;
        text-decoration: line-through;
      }
    }
    .validity-list {
      display: flex;
      align-items: center;
      & > div {
        width: 100px;
        height: 32px;
        line-height: 20px;
        border-radius: 33px;
        background-color: rgba(255, 255, 255, 1);
        color: #000;
        font-size: 14px;
        text-align: center;
        line-height: 32px;
        font-family: Roboto;
        border: 1px solid transparent;
        cursor: pointer;
        margin-right: 20px;
        border: 1px solid transparent;
        box-sizing: border-box;
      }
      .validity-active {
        border-color: rgba(252, 96, 12, 1);
        color: rgba(189, 49, 36, 1);
      }
    }
    .submit-order-btn {
      background: $primary;
      width: 120px;
      height: 42px;
      line-height: 23px;
      border-radius: 2px;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      text-align: center;
      line-height: 42px;
      font-family: Roboto;
      cursor: pointer;
      margin: 26px 0 12px 0;
      user-select: none;
    }
    .current-login {
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
      text-align: left;
      font-family: SourceHanSansSC-regular;
    }
  }
}
.main-box {
  width: 100%;
  margin-top: 8px;
  background: #fff;
}
.content-box {
  .content-box-top {
    padding: 0 0 12px 0;
    & > p {
      color: $primary;
      font-size: 20px;
      font-family: SourceHanSansSC-regular;
      font-weight: 550;
    }
    .mut-btn {
      width: 103px;
      height: 36px;
      line-height: 20px;
      border-radius: 2px;
      background-color: $primary;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      text-align: center;
      line-height: 36px;
      font-family: Roboto;
      cursor: pointer;
      user-select: none;
    }
  }
}
.select-item {
  width: 88px;
  height: 88px;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  .chekbox-box {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 1px;
    right: 1px;
    border-radius: 3px;
    background-color: #ca151d;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.p-title {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: SourceHanSansSC-bold;
  font-weight: 550;
  line-height: normal;
}
.othenr {
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
  font-family: SourceHanSansSC-regular;
  line-height: normal;
}
.othenr:nth-child(2) {
  padding: 12px 0 6px 0;
}
.select-item-active {
  border-color: #ca151d;
}
.detail-title {
  width: 80px;
  color: $primary;
  font-size: 20px;
  font-family: SourceHanSansSC-regular;
  font-weight: 550;
  border-bottom: 3px solid $primary;
  padding-bottom: 8px;
  margin: 0 auto 20px auto;
}
.sale-price {
  color: rgba(255, 58, 48, 1);
  font-weight: 550;
}
.base-price {
  font-weight: 550;
  text-decoration: line-through;
}
.ellipsisText3 {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3; // 设置两行文字溢出
  -webkit-box-orient: vertical;
}
.ellipsisText2 {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2; // 设置两行文字溢出
  -webkit-box-orient: vertical;
}
.bor-box {
  border: 1px solid #efefef;
}
.con-box {
  height: 100%;
  background: rgb(245, 245, 245);
}
.mobile-bg {
  width: 100vw;
  height: 235px;
  background-size: cover;
  background-position: center center;
  .mb-in {
    padding: 28px 12px 0 13px;
    box-sizing: border-box;
    .mobile-title {
      color: rgba(16, 16, 16, 1);
      font-size: 1.04em;
      font-weight: 500;
    }
    .mobile-info {
      height: 100px;
      padding-top: 8px;
      color: rgba(16, 16, 16, 1);
      font-size: 1.08em;
      font-weight: bolder;
    }
    .validity-list {
      display: flex;
      align-items: center;
      padding-top: 10px;
      & > div {
        width: 100px;
        height: 32px;
        line-height: 20px;
        border-radius: 33px;
        background-color: rgba(255, 255, 255, 1);
        color: #000;
        font-size: 14px;
        text-align: center;
        line-height: 32px;
        font-family: Roboto;
        border: 1px solid transparent;
        cursor: pointer;
        margin-right: 20px;
        border: 1px solid transparent;
        box-sizing: border-box;
      }
      .validity-active {
        border-color: rgba(252, 96, 12, 1);
        color: rgba(189, 49, 36, 1);
      }
    }
  }
}
.price-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 14px 13px 16px;
  box-sizing: border-box;
  & > p {
    width: 50%;
    color: rgba(204, 29, 69, 1);
    font-size: 1.3em;
    font-weight: bold;
  }
  & > span {
    width: 50%;
    display: inline-block;
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
  }
}
.category-box {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  padding: 0 16px 27px 16px;
  box-sizing: border-box;
}
.pub-title {
  padding-left: 16px;
  font-size: 1.3em;
  font-weight: bold;
}
.goods-list {
  margin-top: 23px;
  .no-b {
    & > div:nth-last-child(2) {
      border-bottom: none;
    }
  }
  .bb {
    display: flex;
    flex-wrap: wrap;
    & > div:nth-child(2n + 1) {
      border-right: 1px solid #e5e5e5;
    }
    & > div:nth-child(1) {
      border-top: none;
    }
    & > div:nth-child(2) {
      border-top: none;
    }
    & > div:last-child {
      border-bottom: none;
    }
    // & > div:nth-last-child(2) {
    //   border-bottom: none;
    // }
    & > div {
      width: 49vw;
      // height: 150px;
      background: #f5faff;
      border-bottom: 1px solid #e5e5e5;
      padding: 10px;
      box-sizing: border-box;
      .i-title {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        & > p {
          font-weight: bold;
          width: 80%;
          padding-left: 8px;
          box-sizing: border-box;
        }
        & > img {
          width: 20px;
          height: 20px;
        }
      }
      .ino {
        color: #999;
      }
      .ppp {
        color: #cc1d45;
      }
    }
  }
}
</style>
